.react-calendar {
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px;
  font-family: 'Roboto', sans-serif;
}

.react-calendar__tile {
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
}

.react-calendar__tile--active {
  background-color: #4285F4; /* Azul Google */
  color: white;
  border-radius: 50%;
}

.react-calendar__tile--now {
  background-color: #f1f3f4;
  border-radius: 50%;
  font-weight: bold;
}

.tile-holiday {
  background-color: #F4B400; /* Amarelo Google */
  color: white !important;
  border-radius: 50%;
}

.tile-today {
  background-color: #34A853; /* Verde Google */
  color: white !important;
  border-radius: 50%;
}

.tile-default {
  background-color: white;
  color: black;
  border-radius: 5px;
}

.react-calendar__tile:hover {
  background-color: #e8f0fe;
}
.feriados-nacionais {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  background-color: #f1f3f4;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feriado-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.feriado-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feriado-date {
  font-weight: bold;
  color: #4285F4; /* Azul Google */
}

.feriado-name {
  font-size: 14px;
  color: #5f6368; /* Cinza Google */
}
